// src/AnonymousScreen/components/ForgotPasswordPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/css/register.css';
import axios from 'axios';
import Header from './Header';

const ForgotPasswordPage = () => {
  // State for form fields
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1); // Track the step
  // Routing
  const navigate = useNavigate();
  const location = useLocation();
  const { id, logoUrl, emergencyMessage, clinicName, fullClinicName, visitsData, visitId, visitName, currentPage, serviceId } = location.state || {};
  // Handle form field changes
  const handleEmailChange = (e) => setEmail(e.target.value);
  const [config, setConfig] = useState(null); // config for APIs

  // For API config.json
  useEffect(() => {
    // Function for reading config.json
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    // Read API config.json
    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on mount

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (step === 1) {
      if (!email) {
        setErrorMessage('Please fill out your email');
        return;
      }
      // Call the API to handle forgot password logic

      
      try {
        const apiUrl = config.API_FORGOT_PASSWORD; // Read from config.json
        const response = await axios.post(
          apiUrl,
          { email },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true, // Enable sending cookies and credentials
          }
        );
        
        const { RESPONSE_CODES } = response.data;
        
        if (RESPONSE_CODES.includes('U_UNAUTHORIZED_FOR_C')) {
          setErrorMessage('Email not found in our database. Please check and try again.');
          return;
        } else if (RESPONSE_CODES.includes('U_PASS_CHANGE_REQUESTED')) {
          setStep(6);
          return;
        } else {
          setErrorMessage('Some error occurred. Please try again later.');
          return;
        }
        
        
      } catch (error) {
        setErrorMessage('Failed to process your request. Please try again later.');
        console.error('Error during forgot password:', error);
      }
      
    }
  };

  // Handle back navigation
  const handleBackButton = () => {
    if (step === 1) {
      navigate(-1); // Go back to the previous page if on step 1
    } else {
      navigate('/login', { 
        state: { 
          id, 
          logoUrl, 
          emergencyMessage, 
          clinicName, 
          fullClinicName, 
          visitsData,
          visitId,
          visitName,
          currentPage,
          serviceId
        } });
    }
  };

  const handleDoneButtonNavigation = () => {
    navigate('/login', { 
      state: { 
        id, 
        logoUrl, 
        emergencyMessage, 
        clinicName, 
        fullClinicName, 
        visitsData,
        visitId,
        visitName,
        currentPage,
        serviceId
      } });
  };

  return (
    <section className="container">
      <header className="header-wrapper">
        <div className="header-content">
          <img
            src="assets/images/back.svg"
            alt="Back"
            className="header-icon"
            onClick={handleBackButton}
          />
          <h1 className="header-title">Forgot Password</h1>
          <img
            src="assets/images/close.svg"
            alt="Landing"
            className="header-icon"
            onClick={() => navigate(`/${id}`)}
          />
        </div>
      </header>

      <Header 
      logoUrl={logoUrl}
      clinicName={clinicName} // Pass clinicName as a string
      fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      <main className="main-content">
        
        <form className="form-container" onSubmit={handleSubmit}>
          {/* Step 1: Email */}
          {step === 1 && (
            <>
              <h2 className="form-title">Enter the email associated with your account</h2>
              <div className="input-wrapper">
                <label htmlFor="email" className="input-label">Email</label>
                <input
                  type="email"
                  id="email"
                  className="input-field"
                  value={email}
                  onChange={handleEmailChange}
                  aria-label="Email"
                />
              </div>
            </>
          )}

          {/* Step 6: Success Message */}
          {step === 6 && (
            <section className="temp-password-container2">
              <div className="temp-password-content">
                <header className="temp-password-header">
                  <p>We sent a password confirmation to:</p>
                </header>
                <p className="temp-password-email2">{email}</p>
                <div className="temp-password-instructions">
                  <p>
                    Please follow the instructions in your email to reset your password.
                  </p>
                  <p>
                    Didn't receive the email, Check your spam folder or please wait <strong>30 seconds</strong> to resend!
                  </p>
                </div>
              </div>
            </section>
          )}

          {/* Error Message */}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </main>

      {/* Footer for submission */}
      {step !== 6 && (
        <footer className="footer-wrapper">
          <button
            type="button"
            className="submit-button"
            onClick={handleSubmit}
          >
            Send
          </button>
        </footer>
      )}

      {step === 6 && (
        <footer className="footer-wrapper">
          <button
            className="submit-button"
            type="button"
            onClick={handleDoneButtonNavigation}
          >
            Done
          </button>
        </footer>
      )}
    </section>
  );
};

export default ForgotPasswordPage;
