import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import UserGreeting from './UserGreeting';
import NavigationMenu from './NavigationMenu';

const DashboardVisitListsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const visitsData = useMemo(() => state?.visitsData || [], [state?.visitsData]); // Memoize visitsData
  const logoUrl = state?.logoUrl;
  const clinicId = state?.clinicId;
  const clinicName = state?.clinicName;
  const fullClinicName = state?.fullClinicName;

  const patientFirstName = state?.patientFirstName;
  const patientAvatar = state?.patientAvatar;
  const emergencyMessage = state?.emergencyMessage;
  const patientNotifications = state?.patientNotifications;

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (visitsData.length === 1) {
      setShowPopup(true);

      const timeout = setTimeout(() => {
        setShowPopup(false);
        const visit = visitsData[0];
        navigate('/dashboard/initiate-payment', {
          state: {
            clinicId,
            patientFirstName,
            patientAvatar,
            emergencyMessage,
            patientNotifications,
            visitId: visit.visitId,
            visitName: visit.visitName,
            visitFee: visit.visitFee,
            logoUrl,
            clinicName,
            fullClinicName
          },
        });
      }, 3000);

      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [clinicId, visitsData, navigate, patientFirstName, patientAvatar, emergencyMessage, patientNotifications, logoUrl, clinicName, fullClinicName]);

  const handleVisitClick = (visitId, visitName, visitFee) => {
    navigate('/dashboard/initiate-payment', {
      state: {
        clinicId,
        patientFirstName,
        patientAvatar,
        emergencyMessage,
        patientNotifications,
        visitId,
        visitName,
        visitFee,
        logoUrl,
        clinicName,
        fullClinicName
      },
    });
  };

  return (
    <section className="container">
      <Header 
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>
      <div className="info-message">Please select from these available visits</div>
      {visitsData.map((visit) => (
        <div
          key={visit.visitId}
          className="visit-card"
          onClick={() => handleVisitClick(visit.visitId, visit.visitName, visit.visitFee)}
        >
          <div className="visit-title">{visit.visitName}</div>
          <div className="visit-subtitle">
            {visit.allowOnDemand ? 'Available Now' : 'Appointment Only'}
          </div>
        </div>
      ))}

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-icon">🎉</div>
            <p>{`${visitsData[0]?.visitName} selected. Redirecting to payment...`}</p>
          </div>
        </div>
      )}

      <footer>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default DashboardVisitListsPage;
