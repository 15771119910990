import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';

// Enable cookies for axios
axios.defaults.withCredentials = true;

const LoginPage = () => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorNewPassMessage, setErrorNewPassMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false); 
  const navigate = useNavigate();
  const location = useLocation();
  const { id, logoUrl, emergencyMessage, clinicName, currentPage, serviceId, visitsData, visitId, visitName, fullClinicName, visitFee } = location.state || {}; // read from passed State
  const [config, setConfig] = useState(null); // config for APIs
  const [step, setStep] = useState(1); // Track the step
  const [userData, setUserData] = useState(null);
  const [titleText, setTitleText] = useState('Login');

  // For API config.json
  useEffect(() => {
    // Function for reading config.json
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    // Read API config.json
    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on mount

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  // const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  // const handleRePasswordChange = (e) => setRePassword(e.target.value);

  const handlePasswordToggle = () => setShowPassword(!showPassword); // Handle password visibility toggle
  const handleNewPasswordToggle = () => setShowNewPassword(!showNewPassword); // Handle password visibility toggle
  const handleRePasswordToggle = () => setShowRePassword(!showRePassword); // Handle password visibility toggle

  const [validations, setValidations] = useState({
    length: false,
    number: false,
    special: false,
    capital: false,
    lowercase: false,
    match: false,
  });

  const validatePassword = (password, rePassword) => {
    // Only validate if password is not empty
    if (password !== "") {
      const validations = {
        length: password.length >= 8,
        number: /\d/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>_\-[\];'/+=`~\\]/.test(password),
        capital: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        match: password === rePassword,
      };
      setValidations(validations);
    } else {
      // If password is empty, set all validations to false
      setValidations({
        length: false,
        number: false,
        special: false,
        capital: false,
        lowercase: false,
        match: false,
      });
    }
  };
  
  
  

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword, rePassword);
  };

  const handleRePasswordChange = (e) => {
    const rePassword = e.target.value;
    setRePassword(rePassword);
    validatePassword(newPassword, rePassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setErrorMessage('Please enter both email and password');
      return;
    }

    try {
      // const apiUrl = process.env.REACT_APP_API_LOGIN; // Read from .env
      const apiUrl = config.API_LOGIN; // Read from config.json
      const response = await axios.post(apiUrl,
        { username, password, clinicId: id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = response.data;
      setUserData(data); // Store data in state

      if (data.RESPONSE_CODES[0] === 'U_AUTHENTICATED') {

        if (data.RESPONSE_CODES[1] && data.RESPONSE_CODES[1] === 'U_PASS_MUST_RESET') {
          // Set New Password.
          setTitleText('Set New Password');
          setStep(2);
        }
        else{
          setErrorMessage('');
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
            if(currentPage === 'home')
            {
              navigate(`/${id}`); // redirect to home -> then dashboard with user data
            }
            else if(currentPage === 'service')
            {
                navigate(`/dashboard/service/${serviceId}/${id}`, {
                  state: {
                    clinicId:id,
                    logoUrl: logoUrl,
                    patientFirstName: data?.RESPONSE?.patient?.patientFirstName,
                    patientLastName: data?.RESPONSE?.patient?.patientLastName,
                    patientAvatar: data?.RESPONSE?.patient?.patientAvatar,
                    emergencyMessage: emergencyMessage,
                    patientNotifications: data?.RESPONSE?.patient?.patientNotifications,
                    clinicName:clinicName,
                    fullClinicName
                  },
                });
            }
            else if(currentPage === 'visits')
            {
                navigate('/dashboard/visit-lists', {
                  state: {
                    clinicId:id,
                    visitsData,
                    logoUrl,
                    patientFirstName: data?.RESPONSE?.patient?.patientFirstName,
                    patientLastName: data?.RESPONSE?.patient?.patientLastName,
                    patientAvatar: data?.RESPONSE?.patient?.patientAvatar,
                    emergencyMessage,
                    patientNotifications: data?.RESPONSE?.patient?.patientNotifications,
                    clinicName,
                    fullClinicName
                  },
                });
            }
            else if(currentPage === 'visit')
            {
              navigate('/dashboard/initiate-payment', {
                state: {
                  clinicId: id,
                  patientFirstName: data?.RESPONSE?.patient?.patientFirstName,
                  patientAvatar: data?.RESPONSE?.patient?.patientAvatar,
                  emergencyMessage,
                  patientNotifications: data?.RESPONSE?.patient?.patientNotifications,
                  visitId,
                  visitName,
                  visitFee,
                  logoUrl,
                  clinicName,
                  fullClinicName
                },
              });
            }
            else{
              navigate(`/${id}`);
            }
          }, 3000);
        }
        
      } else {
        setErrorMessage('Invalid Email or Password');
        setShowPopup(false);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setErrorMessage('Something went wrong. Please try again later.');
      setShowPopup(false);
    }
  };

  const handleGoBack = () => {
    // Use navigate to go to the /register page and pass the id as state
    navigate('/register', { state: { id, logoUrl, emergencyMessage, clinicName, fullClinicName, visitId,
      visitName, currentPage, visitsData } });
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password', { 
      state: { 
        id, 
        logoUrl, 
        emergencyMessage, 
        clinicName, 
        fullClinicName,
        visitsData,
        visitId,
        visitName,
        currentPage,
        serviceId
      } });
  };

  const handleSetNewPassSubmit = async (e) => {
    e.preventDefault();
    setErrorNewPassMessage('');

    if (step === 2) {
      
      // Call the API to handle forgot password logic
      if (!newPassword) {
        setErrorNewPassMessage('Please enter the New Password');
        return;
      }

      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_[\];'/+=`~\\#^().>,<|])[A-Za-z\d@$!%*?&\-_[\];'/+=`~\\#^().>,<|]{8,}$/;

      if (!passwordRegex.test(newPassword)) {
        setErrorNewPassMessage(
          'Please follow the password instructions to set your new password.'
        );
        return;
      }




      if (rePassword !== newPassword) {
        setErrorNewPassMessage('Passwords Mismatch');
        return;
      }
      
      try {
        // const apiUrl = process.env.API_SET_NEW_PASSWORD; // Read from .env
        const apiUrl = config.API_SET_NEW_PASSWORD; // Read from config.json
        const response = await axios.post(apiUrl,
          { username, password: password, newPassword : newPassword },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
  
        const data = response.data;
        setUserData(data);
  
        if (data.RESPONSE_CODES[0] === 'U_PASS_CHANGE_ERROR') {
          setErrorNewPassMessage('Some error occurred during Set New Password.');
        } 
        else if (data.RESPONSE_CODES[0] === 'U_AUTHENTICATED' && data.RESPONSE_CODES[1] === 'U_PASS_CHANGED') {
          setStep(3);
        }
        else {
          setErrorNewPassMessage('Some unknown error occurred during Set New Password.');
        }
      } catch (error) {
        console.error('Error in Password Reset:', error);
        setErrorNewPassMessage('Something went wrong. Please try again later.');
      }
        
    }
  };

  const handleNavigationFromPasswordReset = () => {
    if (!userData) {
      console.warn('User data not available');
      return;
    }

    const patientData = userData?.RESPONSE?.patient;

    if(currentPage === 'home')
      {
        navigate(`/${id}`); // redirect to home -> then dashboard with user data
      }
      else if(currentPage === 'service')
      {
          navigate(`/dashboard/service/${serviceId}/${id}`, {
            state: {
              clinicId:id,
              logoUrl,
              patientFirstName: patientData?.patientFirstName,
              patientLastName: patientData?.patientLastName,
              patientAvatar: patientData?.patientAvatar,
              emergencyMessage,
              patientNotifications: patientData?.patientNotifications,
              clinicName,
              fullClinicName
            },
          });
      }
      else if(currentPage === 'visits')
      {
          navigate('/dashboard/visit-lists', {
            state: {
              clinicId: id,
              visitsData,
              logoUrl,
              patientFirstName: patientData?.patientFirstName,
              patientLastName: patientData?.patientLastName,
              patientAvatar: patientData?.patientAvatar,
              emergencyMessage,
              patientNotifications: patientData?.patientNotifications,
              clinicName,
              fullClinicName
            },
          });
      }
      else if(currentPage === 'visit')
      {
        navigate('/dashboard/initiate-payment', {
          state: {
            clinicId: id,
            patientFirstName: patientData?.patientFirstName,
            patientLastName: patientData?.patientLastName,
            patientAvatar: patientData?.patientAvatar,
            emergencyMessage,
            patientNotifications: patientData?.patientNotifications,
            visitId,
            visitName,
            visitFee,
            logoUrl,
            clinicName,
            fullClinicName
          },
        });
      }
      else{
        navigate(`/${id}`);
      }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <section className="container">
      <header className="header-wrapper">
        <div className="header-content">
          <img
            src="assets/images/back.svg"
            alt="Back"
            className="header-icon"
            onClick={handleBackButton}
          />
          <h1 className="header-title">{titleText}</h1>
          <img
            src="assets/images/close.svg"
            alt="Landing"
            className="header-icon"
            onClick={() => navigate(`/${id}`)}
          />
        </div>
      </header>
      <Header 
      logoUrl={logoUrl} 
      clinicName={clinicName} // Pass clinicName as a string
      fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />
      <main className="main-content">
      {step === 1 && (
        <section className="password-input-container">
          <div className="password-field">
            <label htmlFor="email" className="password-label">Email</label>
            <div className="password-input-wrapper">
              <input
                type="text"
                id="email"
                className="password-input"
                placeholder=""
                aria-label="Enter your email"
                value={username}
                onChange={handleEmailChange}
              />
            </div>
          </div>

          <div className="password-field">
            <label htmlFor="password" className="password-label">Password</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                id="password"
                className="password-input"
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <button className="password-toggle" onClick={handlePasswordToggle} aria-label="Toggle password visibility">
                <img
                  src="/assets/images/eye.svg"
                  alt="Toggle visibility"
                  width="16"
                  height="16"
                />
              </button>
            </div>
          </div>

          <div className="password-field">
            <label htmlFor="gender" className="input-label">Select Language</label>
            <select id="gender" className="input-field" aria-label="Gender">
              <option value="english">English</option>
            </select>
          </div>

          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <div className="actions-wrapper">
            <button type="submit" className="submit-button" onClick={handleSubmit}>
              Continue
            </button>
            <button type="button" className="back-link" onClick={handleForgotPassword}>
              Forgot password?
            </button>
            <button className="back-link" onClick={handleGoBack} aria-label="Go back to sign up">
              <img
                src="/assets/images/go-back.svg"
                alt="Back"
                className="back-icon"
                aria-hidden="true"
              />
              <span>Go Back to the Sign Up</span>
            </button>
          </div>
        </section>
      )}

      {step === 2 && (
          <section className="password-input-container">
            <div className="password-field">
              <label htmlFor="newPassword" className="password-label">Password</label>
              <div className="password-input-wrapper">
                <input
                  type={showNewPassword ? "text" : "password"} // Toggle password visibility
                  id="newPassword"
                  className="password-input"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
                <button type='button' className="password-toggle" onClick={handleNewPasswordToggle} aria-label="Toggle password visibility">
                  <img
                    src="/assets/images/eye.svg"
                    alt="Toggle visibility"
                    width="16"
                    height="16"
                  />
                </button>
              </div>
            </div>
            <div className="password-field">
              <label htmlFor="rePassword" className="password-label">Confirm Password</label>
              <div className="password-input-wrapper">
                <input
                  type={showRePassword ? "text" : "password"} // Toggle password visibility
                  id="rePassword"
                  className="password-input"
                  value={rePassword}
                  onChange={handleRePasswordChange}
                />
                <button type='button' className="password-toggle" onClick={handleRePasswordToggle} aria-label="Toggle password visibility">
                  <img
                    src="/assets/images/eye.svg"
                    alt="Toggle visibility"
                    width="16"
                    height="16"
                  />
                </button>
              </div>
            </div>
            {errorNewPassMessage && <div className="error-message">{errorNewPassMessage}</div>}
            <div className="password-validation" role="list" aria-label="Password requirements">
              <div className="validation-item" role="listitem" id="length-validation">
                <img
                  loading="lazy"
                  src={validations.length ? "assets/images/mark.svg" : "assets/images/cross.svg"}
                  className="validation-icon"
                  alt="icon"
                />
                <div className="validation-text" style={{ color: validations.length ? "#12b76a" : "#d9534f" }}>
                  Must be at least 8 characters
                </div>
              </div>
              <div className="validation-item" role="listitem" id="number-validation">
                <img
                  loading="lazy"
                  src={validations.number ? "assets/images/mark.svg" : "assets/images/cross.svg"}
                  className="validation-icon"
                  alt="icon"
                />
                <div className="validation-text" style={{ color: validations.number ? "#12b76a" : "#d9534f" }}>
                  Must be at least 1 number
                </div>
              </div>
              <div className="validation-item" role="listitem" id="special-validation">
                <img
                  loading="lazy"
                  src={validations.special ? "assets/images/mark.svg" : "assets/images/cross.svg"}
                  className="validation-icon"
                  alt="icon"
                />
                <div className="validation-text" style={{ color: validations.special ? "#12b76a" : "#d9534f" }}>
                  Must be at least 1 special character
                </div>
              </div>
              <div className="validation-item" role="listitem" id="capital-validation">
                <img
                  loading="lazy"
                  src={validations.capital ? "assets/images/mark.svg" : "assets/images/cross.svg"}
                  className="validation-icon"
                  alt="icon"
                />
                <div className="validation-text" style={{ color: validations.capital ? "#12b76a" : "#d9534f" }}>
                  Must be at least 1 capital letter
                </div>
              </div>
              <div className="validation-item" role="listitem" id="lowercase-validation">
                <img
                  loading="lazy"
                  src={validations.lowercase ? "assets/images/mark.svg" : "assets/images/cross.svg"}
                  className="validation-icon"
                  alt="icon"
                />
                <div className="validation-text" style={{ color: validations.lowercase ? "#12b76a" : "#d9534f" }}>
                  Must be at least 1 lower case
                </div>
              </div>
              <div className="validation-item" role="listitem" id="match-validation">
                <img
                  loading="lazy"
                  src={validations.match ? "assets/images/mark.svg" : "assets/images/cross.svg"}
                  className="validation-icon"
                  alt="icon"
                />
                <div className="validation-text" style={{ color: validations.match ? "#12b76a" : "#d9534f" }}>
                  Passwords must match
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Step 3: Success Message */}
        {step === 3 && (
            <section className="temp-password-container">
              <h1 className="temp-password-title">Your password was successfully accepted</h1>
              <div className="icon-container-register" role="img" aria-label="Featured success icon">
                <img
                    loading="lazy"
                    src="assets/images/mark.svg"
                    className="icon-image"
                    alt=""
                />
              </div>
              <div className="temp-password-content2">
                <p className="temp-password-text">Please use this password in your future access to your account</p>
              </div>
            </section>
          )}

      </main>

      {/* Success Pop-Up */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-icon">🎉</div>
            <p>Login successful! Redirecting...</p>
          </div>
        </div>
      )}

      {/* Footer for submission */}
      {step === 2 && (
        <footer className="footer-wrapper">
          <button
            type="button"
            className="submit-button"
            onClick={handleSetNewPassSubmit}
          >
            Continue
          </button>
        </footer>
      )}

      {(step === 3 ) && (
        <footer className="footer-wrapper">
          <button
            className="submit-button"
            type="button"
            onClick={handleNavigationFromPasswordReset}
          >
            Done
          </button>
        </footer>
      )}
    </section>
  );
};

export default LoginPage;
