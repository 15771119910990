import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import UserGreeting from "./UserGreeting";
import NavigationMenu from "./NavigationMenu";

const PaymentSuccessPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate(); // useNavigate for navigation

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    visitName,
    visitFee,
    logoUrl,
    clinicName,
    fullClinicName,
    patientPhone,
    patientState
  } = state || {};

  const handleNextClick = () => {
    navigate("/appointment-selection", {
      state: {
        clinicId,
        patientFirstName,
        patientAvatar,
        patientNotifications,
        logoUrl,
        clinicName,
        fullClinicName,
        patientPhone,
        patientState 
      }
    });
  };

  return (
    <section className="container">
      <Header logoUrl={logoUrl} clinicName={clinicName} fullClinicName={fullClinicName} />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      <div className="payment-container">
        <section className="temp-password-container">
            <h1 className="temp-password-title">Payment was successful</h1>
            <div className="icon-container-register" role="img" aria-label="Featured success icon">
            <img
                loading="lazy"
                src="assets/images/mark.svg"
                className="icon-image"
                alt="Success Icon"
            />
            </div>
            <div className="temp-password-content2">
                <p>Your payment was successful. Proceed to select your appointment.</p>
            </div>
        </section>
        <div className="plan-card">
          <div className="plan-header">
            <div className="plan-title">{visitName}</div>
            <img loading="lazy" src="../assets/images/tick.svg" className="info-icon" alt="Checkmark" />
          </div>
          <div className="plan-body">
            <div className="price-container">
              <div className="price">${visitFee}</div>
            </div>
          </div>
        </div>
        
      </div>
      <footer>
        <nav
          className="navigation-container-collect-info"
          role="navigation"
          aria-label="Page navigation"
        >
          <button
            type="button"
            className="submit-button"
            aria-label="Finish"
            onClick={handleNextClick}
          >
            Next
          </button>
        </nav>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default PaymentSuccessPage;
