import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import UserGreeting from "./UserGreeting";
import NavigationMenu from "./NavigationMenu";

const AppointmentSelectionPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [config, setConfig] = useState(null);
  const [modalityInfo, setModalityInfo] = useState([]);
  const [questionnaireAltId, setQuestionnaireAltId] = useState(null);
  const [selectedOption, setSelectedOption] = useState("provider-now");
  const [selectedProvider, setSelectedProvider] = useState("any");
  const [doctors, setDoctors] = useState([]);

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    logoUrl,
    clinicName,
    fullClinicName,
    patientPhone,
    patientState,
  } = state || {};

  // Load the config on component mount
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const configData = await response.json();
        console.log("Config loaded:", configData);  // Log the config object
        setConfig(configData);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);
  

  // Fetch doctors and other data only when config is loaded
  useEffect(() => {
    if (!config) return; // Don't fetch if config is not yet available

    const fetchDoctors = async () => {
      try {
        const apiUrl = config.API_DOCTOR_LIST;
        if (!apiUrl) {
          console.error("API_DOCTOR_LIST is not available in config");
          return;
        }

        console.log("API URL:", apiUrl);
        const response = await axios.get(apiUrl, { withCredentials: true });

        const clinicians = response.data.RESPONSE.clinicians || [];
        setDoctors(clinicians);
        setModalityInfo(response.data.RESPONSE.visitTypeInfo.modality || []);
        setQuestionnaireAltId(response.data.RESPONSE.visitTypeInfo.intakeQuestionnaire.altId || null);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, [config]); // This effect depends on the `config`

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  const handleNextClick = () => {
    if (selectedOption === "provider-now") {
      navigate("/collect-information", {
        state: {
          clinicId,
          patientFirstName,
          patientAvatar,
          patientNotifications,
          logoUrl,
          clinicName,
          fullClinicName,
          patientPhone,
          patientState,
          modalityInfo,
          questionnaireAltId,
          selectedProvider,
        },
      });
    } else if (selectedOption === "future-appointment") {
      navigate("/select-provider", {
        state: {
          clinicId,
          patientFirstName,
          patientAvatar,
          patientNotifications,
          logoUrl,
          clinicName,
          fullClinicName,
          patientPhone,
          patientState,
        },
      });
    }
  };

  return (
    <section className="container">
      <Header logoUrl={logoUrl} clinicName={clinicName} fullClinicName={fullClinicName} />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      <main className="main-content">
        <h2 className="form-title">Select</h2>

        <div className="provider-selection-item">
          <div className="selection-group">
            <div className="radio-input-appointment">
              <input
                type="radio"
                id="provider-now"
                name="provider"
                value="provider-now"
                className="radio-base"
                aria-label="Select provider option"
                checked={selectedOption === "provider-now"}
                onChange={handleOptionChange}
              />
              <label htmlFor="provider-now" className="radio-label">
                See a provider now
              </label>
            </div>
            <div className="dropdown-row">
              <div className="dropdown-content">
                <div className="select-wrapper">
                  <select
                    className="provider-select"
                    aria-label="Choose provider"
                    value={selectedProvider}
                    onChange={handleProviderChange}
                  >
                    <option value="any">Any</option>
                    {doctors.map((doctor) => (
                      <option key={doctor.clinicianId} value={doctor.clinicianId}>
                        {`${doctor.prefix} ${doctor.firstName} ${doctor.lastName} ${doctor.suffix}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="radio-group-item-appointment-provider">
          <div className="radio-appointment-provider">
            <div className="input-appointment-provider">
              <div className="radio-2-appointment-provider">
                <input
                  type="radio"
                  id="future-appointment"
                  name="provider"
                  value="future-appointment"
                  className="radio-base-appointment-provider"
                  aria-label="Book a future appointment"
                  checked={selectedOption === "future-appointment"}
                  onChange={handleOptionChange}
                />
              </div>
            </div>
            <label htmlFor="future-appointment" className="text-appointment-provider">
              Book a future appointment
            </label>
          </div>
        </div>

        <footer className="footer-appointment-provider">
          <button
            type="button"
            className="submit-button-appointment-provider"
            onClick={handleNextClick}
          >
            Next
          </button>
        </footer>
      </main>

      <footer>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default AppointmentSelectionPage;
