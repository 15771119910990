import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import UserGreeting from './UserGreeting';
import NavigationMenu from './NavigationMenu';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
 
const BookingQuestionnairePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(null);
  const [formId, setFormId] = useState(null);
  const [error, setError] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [config, setConfig] = useState(null); // config for APIs

  // Retrieve state values
  const clinicId = state?.clinicId;
  const logoUrl = state?.logoUrl;
  const patientFirstName = state?.patientFirstName;
  const patientAvatar = state?.patientAvatar;
  const patientNotifications = state?.patientNotifications;
  const clinicName = state?.clinicName;
  const fullClinicName = state?.fullClinicName;
  const selectedDoctor = state?.selectedDoctor;
  const selectedDoctorInfo = state?.selectedDoctorInfo;
  const selectedTimeslot = state?.selectedTimeslot;
  const selectedEndTimeslot = state?.selectedEndTimeslot;
  const selectedModality = state?.selectedModality;
  const dob = state?.dob;
  const visitName = state?.visitName;
  const bookingQuestionnaireAltId = state?.bookingQuestionnaireAltId;
  const patientPhone = state?.patientPhone;
  const patientState = state?.patientState;

  // For API config.json
  useEffect(() => {
    // Function for reading config.json
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    // Read API config.json
    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (config?.API_GET_FORM) { // Only proceed if config and API_SERVICES are available
      const fetchData = async () => {
        try {
          const landingUrl = config?.API_GET_FORM; // Read from config.json
          const apiUrl = `${landingUrl}${bookingQuestionnaireAltId}`;
          
          const response = await fetch(apiUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();

          // Set form data and serviceId from API response
          setFormData(data.RESPONSE.form.form);
          setFormId(data.RESPONSE.formId); // Get form JSON
        } catch (err) {
          console.error('Error fetching intake questionnaire details:', err);
          setError('API or web service is not available this time.');
        }
      };

      fetchData();
    }
  }, [ config?.API_GET_FORM, bookingQuestionnaireAltId]); // Only run if config?.API_SERVICES is available

  useEffect(() => {
    if (formData) {
      const surveyInstance = new Model(formData);
      setSurvey(surveyInstance);

      // Handle survey completion
      surveyInstance.onComplete.add(async (result) => {
        try {
          const postData = {
            formId: formId,
            answers: Object.entries(result.data).map(([question, answer]) => ({
              question,
              answer,
            })),
          };

          console.log(JSON.stringify(postData));

          const apiUrl = config?.API_SUBMIT_FORM; // Read from config.json
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch visits: ${response.statusText}`);
          }

          // const responseData = await response.json();
          
          navigate("/appointment-scheduled", {
            state: {
              clinicId,
              patientFirstName,
              patientAvatar,
              patientNotifications,
              logoUrl,
              clinicName,
              fullClinicName,
              selectedDoctor,
              selectedDoctorInfo,
              selectedTimeslot,
              selectedEndTimeslot,
              selectedModality,
              dob,
              visitName,
              bookingQuestionnaireAltId,
              patientPhone,
              patientState
            },
          });
        } catch (error) {
          console.error('Error handling intake questionnaire completion:', error);
          setError('Failed to submit intake questionnaire. Please try again later.');
        }
      });
    }
  }, [clinicId, formData, formId,  logoUrl, patientFirstName,  patientAvatar,  
    patientNotifications, navigate, config?.API_GET_FORM, clinicName, fullClinicName, 
    bookingQuestionnaireAltId, config?.API_SUBMIT_FORM, dob, selectedDoctor, selectedEndTimeslot, 
    selectedModality, selectedTimeslot, visitName, patientState, patientPhone, selectedDoctorInfo]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!formData) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
      </div>
    );
  }

  return (
    <section className="container">
      {/* Header */}
      <Header 
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      {/* User Greeting */}
      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      {/* Survey Form */}
      <main className="main-content">
        {survey && <Survey model={survey} />}
      </main>

      {/* Navigation Menu */}
      <NavigationMenu clinicId={clinicId} />
    </section>
  );
};

export default BookingQuestionnairePage;
