// src/App.js
import React, { useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.css';
import './transitions.css'; // CSS for transition animations
import LandingScreen from './AnonymousScreen/LandingScreen';
import LoginPage from './AnonymousScreen/components/LoginPage';
import DashboardScreen from './PatientScreen/DashboardScreen';
import ServiceDetailPage from './AnonymousScreen/components/ServiceDetailPage';
import ServiceDetailsDashboardPage from './PatientScreen/components/ServiceDetailsDashboardPage';
import NotificationsScreen from './PatientScreen/components/NotificationsScreen';
import RegisterPage from './AnonymousScreen/components/RegisterPage';
import VisitListsPage from './AnonymousScreen/components/VisitListsPage';
import DashboardVisitListsPage from './PatientScreen/components/DashboardVisitListsPage';
import InitiatePaymentPage from './PatientScreen/components/InitiatePaymentPage';
import ForgotPasswordPage from './AnonymousScreen/components/ForgotPasswordPage';
import PaymentReturnPage from './PatientScreen/components/PaymentReturnPage';
import PaymentSuccessPage from './PatientScreen/components/PaymentSuccessPage';
import AppointmentSelectionPage from './PatientScreen/components/AppointmentSelectionPage';
import CollectInfoPage from './PatientScreen/components/CollectInfoPage';
import IntakeQuestionnairePage from './PatientScreen/components/IntakeQuestionnairePage';
import BookingQuestionnairePage from './PatientScreen/components/BookingQuestionnairePage';
import WaitingQueuePage from './PatientScreen/components/WaitingQueuePage';
import SelectProviderPage from './PatientScreen/components/SelectProviderPage';
import AppointmentScheduledPage from './PatientScreen/components/AppointmentScheduledPage';

function App() {
  const location = useLocation();
  const nodeRef = useRef(null);

  return (

      <TransitionGroup>
        <CSSTransition
          nodeRef={nodeRef}
          key={location.pathname}
          classNames="fade"
          timeout={300}
        >
          
            <Routes location={location}>
              {/* Route with dynamic ID */}
              <Route path="/" element={<LandingScreen />} />
              <Route path="/:id" element={<LandingScreen />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/dashboard" element={<DashboardScreen />} />
              <Route path="/service/:serviceId/:clinicId" element={<ServiceDetailPage />} />
              <Route path="/dashboard/service/:serviceId/:clinicId" element={<ServiceDetailsDashboardPage />} />
              <Route path="/notifications" element={<NotificationsScreen />} />
              <Route path="/visit-lists" element={<VisitListsPage />} />
              <Route path="/dashboard/visit-lists" element={<DashboardVisitListsPage />} />
              <Route path="/dashboard/initiate-payment" element={<InitiatePaymentPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/payment-status" element={<PaymentReturnPage />} />
              <Route path="/payment-success" element={<PaymentSuccessPage />} />
              <Route path="/appointment-selection" element={<AppointmentSelectionPage />} />
              <Route path="/collect-information" element={<CollectInfoPage />} />
              <Route path="/intake-questionnaire" element={<IntakeQuestionnairePage />} />
              <Route path="/booking-questionnaire" element={<BookingQuestionnairePage />} />
              <Route path="/waiting-queue" element={<WaitingQueuePage />} />
              <Route path="/select-provider" element={<SelectProviderPage />} />
              <Route path="/appointment-scheduled" element={<AppointmentScheduledPage />} />
            </Routes>
          
        </CSSTransition>
      </TransitionGroup>

  );
}

export default App;
