import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserGreeting from "./UserGreeting";
import NavigationMenu from "./NavigationMenu";
import axios from "axios";

const formatDate = (date) => {
  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (date.toDateString() === today.toDateString()) {
    return `Today, ${formattedDate.split(", ")[1]}, ${formattedDate.split(", ")[2]}`;
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return `Tomorrow, ${formattedDate.split(", ")[1]}, ${formattedDate.split(", ")[2]}`;
  } else {
    return formattedDate;
  }
};

const SelectProviderPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [dob, setDob] = useState(new Date());
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [selectedEndTimeslot, setSelectedEndTimeslot] = useState(null);
  const [formattedDate, setFormattedDate] = useState(formatDate(new Date()));
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);
  const [visitTypeInfo, setVisitTypeInfo] = useState(null); // Store visit type info
  const [modalityInfo, setModalityInfo] = useState([]); // Store modality info
  const [selectedModality, setSelectedModality] = useState('text'); // Store selected modality 
  const [bookingQuestionnaireAltId, setBookingQuestionnaireAltId] = useState(null);

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    logoUrl,
    clinicName,
    fullClinicName,
    patientPhone,
    patientState
  } = state || {};

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);

  const fetchDoctors = useCallback(
    (date) => {
      setLoading(true);
      const formattedDate = date.toISOString().split("T")[0];
      const apiUrl = `${config?.API_PROVIDER_LIST}${formattedDate}`;
      axios
        .get(apiUrl, { withCredentials: true })
        .then((response) => {
          const data = response.data;
          setDoctors(data.RESPONSE.clinicians || []);
          if (data.RESPONSE.clinicians.length > 0) {
            setSelectedDoctor(data.RESPONSE.clinicians[0].clinicianId);
            setSelectedTimeslot(
              data.RESPONSE.clinicians[0].availableTimeSlots[0]?.Now
                ? "Now"
                : `${data.RESPONSE.clinicians[0].availableTimeSlots[0]?.startTime}`
            );
            setSelectedEndTimeslot(
              data.RESPONSE.clinicians[0].availableTimeSlots[0]?.Now
                ? "Now"
                : `${data.RESPONSE.clinicians[0].availableTimeSlots[0]?.endTime}`
            );
          }
          // Fetch visitTypeInfo and modalities
          setVisitTypeInfo(data.RESPONSE.visitTypeInfo);
          setModalityInfo(data.RESPONSE.visitTypeInfo.modality || []);
          setBookingQuestionnaireAltId(data?.RESPONSE?.visitTypeInfo?.bookingQuestionnaire?.altId || null);
        })
        .finally(() => setLoading(false));
    },
    [config?.API_PROVIDER_LIST, ]
  );

  useEffect(() => {
    if (config?.API_PROVIDER_LIST) {
      fetchDoctors(new Date());
    }
  }, [config?.API_PROVIDER_LIST, fetchDoctors]);

  const handleDateChange = (date) => {
    setDob(date);
    setFormattedDate(formatDate(date));
    fetchDoctors(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Step 1 logic
    if (step === 1) {
      if (!selectedDoctor || !selectedTimeslot) {
        return;
      }
      
      // Handle modality logic
      const availableModalities = Object.keys(modalityInfo || {}).filter(
        (key) => modalityInfo[key]
      );

      if (availableModalities.length === 1) {
        // If only one modality, post the data to the API
        const payload = {
          schedule: {
            appointmentdate: dob.toISOString().split("T")[0],
            starttime: selectedTimeslot,
            endtime: selectedEndTimeslot,
            modality: availableModalities[0],  // Use the only available modality
            clinicianid: selectedDoctor,
          },
        };
      
        try {
          const apiSaveUrl = config?.API_SCHEDULE_SAVE;
          await axios.post(apiSaveUrl, payload, { withCredentials: true });
      
          // After posting data, check if bookingQuestionnaireAltId is null
          if (bookingQuestionnaireAltId === null) {
            navigate("/appointment-scheduled", {
              state: {
                clinicId,
                patientFirstName,
                patientAvatar,
                patientNotifications,
                logoUrl,
                clinicName,
                fullClinicName,
                selectedDoctor,
                selectedDoctorInfo,
                selectedTimeslot,
                selectedEndTimeslot,
                selectedModality: availableModalities[0],
                dob,
                visitName: visitTypeInfo?.visitName || "",
                bookingQuestionnaireAltId,
                patientPhone,
                patientState,
              },
            });
          } else {
            navigate("/booking-questionnaire", {
              state: {
                clinicId,
                patientFirstName,
                patientAvatar,
                patientNotifications,
                logoUrl,
                clinicName,
                fullClinicName,
                selectedDoctor,
                selectedDoctorInfo,
                selectedTimeslot,
                selectedEndTimeslot,
                selectedModality: availableModalities[0],
                dob,
                visitName: visitTypeInfo?.visitName || "",
                bookingQuestionnaireAltId,
                patientPhone,
                patientState,
              },
            });
          }
        } catch (error) {
          console.error("Error saving schedule:", error);
        }
      }
       else {
        setStep(2);
      }
    } 
    
    // Step 2 logic
    else if (step === 2) {
      try {
        const payload = {
          schedule: {
            appointmentdate: dob.toISOString().split("T")[0],
            starttime: selectedTimeslot,
            endtime: selectedEndTimeslot,
            modality: selectedModality,
            clinicianid: selectedDoctor,
          },
        };
        const apiSaveUrl = config?.API_SCHEDULE_SAVE;
        await axios.post(apiSaveUrl, payload, { withCredentials: true });

        if (bookingQuestionnaireAltId === null) {
          navigate("/appointment-scheduled", {
            state: {
              clinicId,
              patientFirstName,
              patientAvatar,
              patientNotifications,
              logoUrl,
              clinicName,
              fullClinicName,
              selectedDoctor,
              selectedDoctorInfo,
              selectedTimeslot,
              selectedEndTimeslot,
              selectedModality,
              dob,
              visitName: visitTypeInfo?.visitName || "",
              bookingQuestionnaireAltId,
              patientPhone,
              patientState
            },
          });
        } else {
          navigate("/booking-questionnaire", {
            state: {
              clinicId,
              patientFirstName,
              patientAvatar,
              patientNotifications,
              logoUrl,
              clinicName,
              fullClinicName,
              selectedDoctor,
              selectedDoctorInfo,
              selectedTimeslot,
              selectedEndTimeslot,
              selectedModality,
              dob,
              visitName: visitTypeInfo?.visitName || "",
              bookingQuestionnaireAltId,
              patientPhone,
              patientState
            },
          });
        }
      } catch (error) {
        console.error("Error saving schedule:", error);
      }
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate("/appointment-selection", {
        state: {
          clinicId,
          patientFirstName,
          patientAvatar,
          patientNotifications,
          logoUrl,
          clinicName,
          fullClinicName,
          patientPhone,
          patientState
        },
      });
    }
  };

  const selectedDoctorInfo = doctors.find((doctor) => doctor.clinicianId === selectedDoctor) || {};
  console.log(selectedDoctorInfo);
  const appointmentDoctorName = `${selectedDoctorInfo.prefix} ${selectedDoctorInfo.firstName} ${selectedDoctorInfo.lastName} ${selectedDoctorInfo.suffix}`;
  const appointmentDoctorAvatar = selectedDoctorInfo.avatar;

  return (
    <section className="container">
      <Header logoUrl={logoUrl} clinicName={clinicName} fullClinicName={fullClinicName} />
      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>
      <main className="main-content">
        {step === 1 && (
          <>
            <h4>Select a date to book your appointment.</h4>
            <div className="input-wrapper">
              <DatePicker
                selected={dob}
                onChange={handleDateChange}
                className=""
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                scrollableYearDropdown
                dateFormat="dd-MM-yyyy"
                yearDropdownItemNumber={15}
                minDate={new Date()}
                customInput={
                  <div className="custom-date-input">
                    <input className="input-field" value={formattedDate} readOnly />
                    <span className="calendar-icon">📅</span>
                  </div>
                }
              />
            </div>
            {loading ? (
                <div className="loading-container2">
                  <div className="loading-text">Loading filtered data</div>
                  <div className="loading-dot">.</div>
                  <div className="loading-dot">.</div>
                  <div className="loading-dot">.</div>
                </div>
              ) : doctors.length === 0 ? (
                <div className="info-banner-common-nodata">
                  Unfortunately, there are no appointments available with doctors on your selected date. Don't worry, we're here to help you find the care you need. Please select another date.
                </div>
              ) : (
              doctors.map((doctor, index) => (
                <div
                  className={`past-provider-selection ${
                    selectedDoctor === doctor.clinicianId
                      ? "selected-provider"
                      : "unselected-provider"
                  } ${index === doctors.length - 1 ? "last-provider" : ""}`}
                  key={doctor.clinicianId}
                >
                  <div className="div-provider-selection">
                    <div className="div-2-provider-selection">
                      <div className="div-3-provider-selection">
                        <div className="div-4-provider-selection">
                          <img
                            loading="lazy"
                            src={doctor.avatar}
                            className="img-provider-selection"
                            alt={`${doctor.prefix} ${doctor.firstName} ${doctor.lastName} profile`}
                          />
                          <div>
                            {`${doctor.prefix} ${doctor.firstName} ${doctor.lastName} ${doctor.suffix}`}
                          </div>
                        </div>
                        <div className="radio-provider-selection">
                          <input
                            type="radio"
                            name="doctor"
                            className="radio-input-provider-selection"
                            aria-label={`Select ${doctor.prefix} ${doctor.firstName} ${doctor.lastName}`}
                            checked={selectedDoctor === doctor.clinicianId}
                            onChange={() => setSelectedDoctor(doctor.clinicianId)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="div-5-provider-selection">
                      <div className="div-6-provider-selection">
                        <div className="specialties-label-provider-selection">Specialties:</div>
                        <div className="div-7-provider-selection">
                          {doctor.specialties.map((specialty, i) => (
                            <div className="specialty-provider-selection" key={i}>
                              {specialty}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="div-5-provider-selection">
                      <div className="div-6-provider-selection">
                        <div className="specialties-label-provider-selection">Languages:</div>
                        <div className="div-7-provider-selection">
                          {doctor.languagesSpoken.map((language, i) => (
                            <div className="specialty-provider-selection" key={i}>
                              {language}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="div-8-provider-selection">
                      <div className="select-time-provider-selection">Select time</div>
                      <div className="div-9-provider-selection">
                        {doctor.availableTimeSlots.map((timeslot, i) => (
                          <div className="time-slot-item-provider-selection" key={i}>
                            <label className="radio-label-provider-selection">
                            <input
                              type="radio"
                              name={`timeslot-${doctor.clinicianId}`}
                              value={timeslot.Now ? "Now" : `${timeslot.startTime} - ${timeslot.endTime}`}
                              className="radio-input-provider-selection"
                              aria-label={`Select ${timeslot.Now ? "Now" : `${timeslot.startTime}`}`}
                              checked={selectedTimeslot === (timeslot.Now ? "Now" : `${timeslot.startTime}`) && selectedDoctor === doctor.clinicianId}
                              onChange={() => {
                                setSelectedTimeslot(timeslot.Now ? "Now" : `${timeslot.startTime}`);
                                setSelectedEndTimeslot(timeslot.Now ? "Now" : `${timeslot.endTime}`);
                              }}
                            />

                              <span className="text-provider-selection">
                                {timeslot.Now ? "Now" : `${timeslot.startTime}`}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </>
        )}
        {step === 2 && (
          <>
            <div className="div-appointment-schedule">
              <div className="badge-appointment-schedule">
                <div className="badge-base-appointment-schedule">
                  <img
                    loading="lazy"
                    src={appointmentDoctorAvatar}
                    className="img-provider-selection"
                    alt={appointmentDoctorName}
                  /> 
                  <div>{appointmentDoctorName}</div>
                </div>
              </div>
            </div>

            <div className="appointment-card-appointment-schedule-date" role="article">
              <div className="date-container-appointment-schedule-date">
                <div className="date-day-appointment-schedule-date">{new Date(dob).getDate()}</div>
                <div className="date-month-appointment-schedule-date">
                  {new Intl.DateTimeFormat("en-US", { month: "short" }).format(dob)}
                </div>
              </div>
              <div className="appointment-details-appointment-schedule-date">
                <div className="appointment-title-appointment-schedule-date">{visitTypeInfo?.visitName}</div>
                <div className="appointment-time-appointment-schedule-date">
                  <div className="time-slot-appointment-schedule-date">{selectedTimeslot} - {selectedEndTimeslot}</div>
                </div>
              </div>
            </div>

            <div className="div-2-visit-type-selection-appointment">
              {modalityInfo.video === true && (
              <div className={`radio-group-item-visit-type-${selectedModality === "video" ? "selection" : "unselected"}`}>
                <div className="radio-visit-type-selection">
                  <div className="input-visit-type-selection">
                    <input
                      type="radio"
                      name="visit-type"
                      id="video-visit"
                      className="radio-input-visit-type-selection"
                      aria-label="Select video visit"
                      checked={selectedModality === "video"}
                      onChange={() => setSelectedModality("video")}
                    />
                    <label htmlFor="video-visit" className="radio-label-visit-type-selection"></label>
                  </div>
                  <div className="div-3-visit-type-selection">
                    <div className="text-visit-type-selection">Video Visit</div>
                    <div className="badge-visit-type-selection">
                      <div className="badge-base-visit-type-selection">
                        <img
                          loading="lazy"
                          src="/assets/images/video.svg"
                          className="img-visit-type-selection"
                          alt="Video call icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {modalityInfo.audio === true && (
              <div className={`radio-group-item-visit-type-${selectedModality === "audio" ? "selection" : "unselected"}`}>
                <div className="radio-visit-type-selection">
                  <div className="input-visit-type-selection">
                    <input
                      type="radio"
                      name="visit-type"
                      id="phone-visit"
                      className="radio-input-visit-type-selection"
                      aria-label="Select phone visit"
                      checked={selectedModality === "audio"}
                      onChange={() => setSelectedModality("audio")}
                    />
                    <label htmlFor="phone-visit" className="radio-label-visit-type-selection"></label>
                  </div>
                  <div className="div-4-visit-type-selection">
                    <div className="text-2-visit-type-selection">Phone Visit</div>
                    <div className="badge-visit-type-selection">
                      <div className="badge-base-visit-type-selection">
                        <img
                          loading="lazy"
                          src="/assets/images/call.svg"
                          className="img-visit-type-selection"
                          alt="Phone call icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {modalityInfo.text === true && (
              <div className={`radio-group-item-visit-type-${selectedModality === "text" ? "selection" : "unselected"}`}>
                <div className="radio-visit-type-selection">
                  <div className="input-visit-type-selection">
                    <input
                      type="radio"
                      name="visit-type"
                      id="chat-visit"
                      className="radio-input-visit-type-selection"
                      aria-label="Select chat visit"
                      checked={selectedModality === "text"}
                      onChange={() => setSelectedModality("text")}
                    />
                    <label htmlFor="chat-visit" className="radio-label-visit-type-selection"></label>
                  </div>
                  <div className="div-4-visit-type-selection">
                    <div className="text-2-visit-type-selection">Chat Visit</div>
                    <div className="badge-visit-type-selection">
                      <div className="badge-base-visit-type-selection">
                        <img
                          loading="lazy"
                          src="/assets/images/chat.svg"
                          className="img-visit-type-selection"
                          alt="Chat icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            </div>
          </>
        )}
      </main>
      <footer>
        <nav
          className="navigation-container-collect-info"
          role="navigation"
          aria-label="Page navigation"
        >
          <button
            type="button"
            className="nav-button-collect-info nav-button-back-collect-info"
            aria-label="Go to previous page"
            onClick={handleBack}
          >
            <span className="button-content-collect-info back-content-collect-info">Back</span>
          </button>
          <button
            type="button"
            className="nav-button-collect-info nav-button-next-collect-info"
            aria-label="Go to next page"
            onClick={handleSubmit}
          >
            <span className="button-content-collect-info next-content-collect-info">Next</span>
          </button>
        </nav>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default SelectProviderPage;
