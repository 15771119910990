import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import UserGreeting from "./UserGreeting";
import NavigationMenu from "./NavigationMenu";

const CollectInfoPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [phone, setPhone] = useState(state?.patientPhone || "");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(state?.patientState || "");
  const [config, setConfig] = useState(null);
  const [selectedModality, setSelectedModality] = useState("text");

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    logoUrl,
    clinicName,
    fullClinicName,
    modalityInfo,
    questionnaireAltId,
    selectedProvider,
    patientPhone,
    patientState
  } = state || {};

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (!config) return;
      try {
        const apiUrl = config.API_STATE_LIST;
        const response = await axios.get(apiUrl, { withCredentials: true });
        if (
          response.data.RESPONSE_CODES.includes("SUCCESS") &&
          response.data.RESPONSE?.states
        ) {
          setStates(response.data.RESPONSE.states);
        } else {
          console.error("Failed to fetch states.");
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, [config]);

  const handlePhoneChange = (e) => setPhone(e.target.value);

  const handleStateSelect = (e) => setSelectedState(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (step === 1) {
      if (!selectedState) {
        setErrorMessage("Please select your state.");
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (!phone) {
        setErrorMessage("Please confirm your phone number.");
        return;
      }

      // Handle modality logic
      const availableModalities = Object.keys(modalityInfo || {}).filter(
        (key) => modalityInfo[key]
      );

      if (availableModalities.length === 1) {
        setSelectedModality(availableModalities[0]);
        if (questionnaireAltId) {
          navigate("/intake-questionnaire", {
            state: {
              clinicId,
              patientFirstName,
              patientAvatar,
              patientNotifications,
              logoUrl,
              clinicName,
              fullClinicName,
              selectedModality: availableModalities[0],
              questionnaireAltId,
              selectedProvider,
            },
          });
        } else {
          navigate("/waiting-queue", {
            state: {
              clinicId,
              patientFirstName,
              patientAvatar,
              patientNotifications,
              logoUrl,
              clinicName,
              fullClinicName,
              selectedModality: availableModalities[0],
              selectedProvider,
            },
          });
        }
      } else {
        setStep(3); // Proceed to Step 3 if multiple modalities exist
      }
    } else if (step === 3) {
      if (questionnaireAltId) {
        navigate("/intake-questionnaire", {
          state: {
            clinicId,
            patientFirstName,
            patientAvatar,
            patientNotifications,
            logoUrl,
            clinicName,
            fullClinicName,
            selectedModality,
            questionnaireAltId,
            selectedProvider,
          },
        });
      } else {
        navigate("/waiting-queue", {
          state: {
            clinicId,
            patientFirstName,
            patientAvatar,
            patientNotifications,
            logoUrl,
            clinicName,
            fullClinicName,
            selectedModality,
            selectedProvider,
          },
        });
      }
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate("/appointment-selection", {
        state: {
          clinicId,
          patientFirstName,
          patientAvatar,
          patientNotifications,
          logoUrl,
          clinicName,
          fullClinicName,
          patientPhone,
          patientState
        },
      });
    }
  };
  

  return (
    <section className="container">
      <Header
        logoUrl={logoUrl}
        clinicName={clinicName}
        fullClinicName={fullClinicName}
      />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      <main className="main-content">
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          {step === 1 && (
            <>
              <h2 className="form-title">Please confirm your Location.</h2>
              <p>
                Medical regulations differ based on location. We use your state
                to connect you with the applicable licensed provider.
              </p>
              <div className="input-wrapper select2-wrapper">
                <label htmlFor="state" className="input-label">
                Location
                </label>
                <select
                  id="state"
                  value={selectedState || ""}
                  onChange={handleStateSelect}
                  className="input-field state-input select2-input"
                >
                  <option value="" disabled>
                    Select your state
                  </option>
                  {states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <h2 className="form-title">Confirm your phone number.</h2>
              <p>
                In case you or the provider have trouble joining the visit,
                your provider will reach you at the number below.
              </p>
              <div className="input-wrapper">
                <label htmlFor="phone" className="input-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  className="input-field phone-input"
                />
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <p>
                Select your preferred communication method to connect with your
                provider:
              </p>
              <div className="div-2-visit-type-selection-appointment">
                {modalityInfo.video && (
                  <div
                    className={`radio-group-item-visit-type-${
                      selectedModality === "video" ? "selection" : "unselected"
                    }`}
                  >
                    <div className="radio-visit-type-selection">
                      <div className="input-visit-type-selection">
                        <input
                          type="radio"
                          name="visit-type"
                          id="video-visit"
                          className="radio-input-visit-type-selection"
                          aria-label="Select video visit"
                          checked={selectedModality === "video"}
                          onChange={() => setSelectedModality("video")}
                        />
                        <label
                          htmlFor="video-visit"
                          className="radio-label-visit-type-selection"
                        ></label>
                      </div>
                      <div className="div-3-visit-type-selection">
                        <div className="text-visit-type-selection">
                          Video Visit
                        </div>
                        <div className="badge-visit-type-selection">
                          <img
                            loading="lazy"
                            src="/assets/images/video.svg"
                            className="img-visit-type-selection"
                            alt="Video call icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {modalityInfo.audio && (
                  <div
                    className={`radio-group-item-visit-type-${
                      selectedModality === "audio" ? "selection" : "unselected"
                    }`}
                  >
                    <div className="radio-visit-type-selection">
                      <div className="input-visit-type-selection">
                        <input
                          type="radio"
                          name="visit-type"
                          id="phone-visit"
                          className="radio-input-visit-type-selection"
                          aria-label="Select phone visit"
                          checked={selectedModality === "audio"}
                          onChange={() => setSelectedModality("audio")}
                        />
                        <label
                          htmlFor="phone-visit"
                          className="radio-label-visit-type-selection"
                        ></label>
                      </div>
                      <div className="div-4-visit-type-selection">
                        <div className="text-2-visit-type-selection">
                          Phone Visit
                        </div>
                        <div className="badge-visit-type-selection">
                          <img
                            loading="lazy"
                            src="/assets/images/call.svg"
                            className="img-visit-type-selection"
                            alt="Phone call icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {modalityInfo.text && (
                  <div
                    className={`radio-group-item-visit-type-${
                      selectedModality === "text" ? "selection" : "unselected"
                    }`}
                  >
                    <div className="radio-visit-type-selection">
                      <div className="input-visit-type-selection">
                        <input
                          type="radio"
                          name="visit-type"
                          id="chat-visit"
                          className="radio-input-visit-type-selection"
                          aria-label="Select chat visit"
                          checked={selectedModality === "text"}
                          onChange={() => setSelectedModality("text")}
                        />
                        <label
                          htmlFor="chat-visit"
                          className="radio-label-visit-type-selection"
                        ></label>
                      </div>
                      <div className="div-4-visit-type-selection">
                        <div className="text-2-visit-type-selection">
                          Chat Visit
                        </div>
                        <div className="badge-visit-type-selection">
                          <img
                            loading="lazy"
                            src="/assets/images/chat.svg"
                            className="img-visit-type-selection"
                            alt="Chat icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </main>

      <footer>
        <nav
          className="navigation-container-collect-info"
          role="navigation"
          aria-label="Page navigation"
        >
          <button
            type="button"
            className="nav-button-collect-info nav-button-back-collect-info"
            aria-label="Go to previous page"
            onClick={handleBack}
          >
            <span className="button-content-collect-info back-content-collect-info">
              Back
            </span>
          </button>
          <button
            type="button"
            className="nav-button-collect-info nav-button-next-collect-info"
            aria-label="Go to next page"
            onClick={handleSubmit}
          >
            <span className="button-content-collect-info next-content-collect-info">
              Next
            </span>
          </button>
        </nav>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default CollectInfoPage;
