import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import UserGreeting from "./UserGreeting";
import NavigationMenu from "./NavigationMenu";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
axios.defaults.withCredentials = true;

const InitiatePaymentPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState(null);
  const [pubKey, setPubKey] = useState(null);
  const [config, setConfig] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [loadingStripe, setLoadingStripe] = useState(true); 
  const errorMessage = state?.errorMessage || null; // Retrieve error message from state

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    logoUrl,
    clinicName,
    fullClinicName,
    visitId,
  } = state || {};

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    if (config && visitId) {
      const initiateVisit = async () => {
        try {
          const landingUrl = config?.API_VISIT_PAYMENT; 
          const apiUrl = `${landingUrl}${visitId}`;
          const response = await axios.post(apiUrl);

          const { RESPONSE_CODES, RESPONSE } = response.data;

          if (RESPONSE_CODES.includes("U_UNAUTHORIZED_FOR_C")) {
            navigate("/login");
          } else if (RESPONSE_CODES.includes("ERROR")) {
            navigate("/dashboard");
          } else if (RESPONSE_CODES.includes("C_SERVICEVISIT_INITIALIZED")) {
            const { clientSecret, pubKey } = RESPONSE.visitInitInfo;
            setClientSecret(clientSecret);
            setPubKey(pubKey);
          }
        } catch (error) {
          console.error("Error initiating visit:", error);
          navigate("/dashboard");
        }
        setLoading(false);
      };

      initiateVisit();
    }
  }, [navigate, visitId, config]);

  useEffect(() => {
    if (pubKey) {
      setLoadingStripe(false);
    }
  }, [pubKey]);

  return (
    <section className="container">
      <Header logoUrl={logoUrl} clinicName={clinicName} fullClinicName={fullClinicName} />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      <div className="payment-container">
        {errorMessage && (
          <section className="temp-password-container">
              <h1 className="temp-password-title">{errorMessage}</h1>
              <div className="icon-container-payment-failed" role="img" aria-label="Featured success icon">
              <h1 className="icon-text-payment-failed">!</h1>
              </div>
              <div className="temp-password-content2">
                  <p></p>
              </div>
          </section>
        )}
        
        <div className="payment-section">
          {loading ? (
            <div className="loading-container2">
              <div className="loading-text">Loading payment information</div>
              <div className="loading-dot">.</div>
              <div className="loading-dot">.</div>
              <div className="loading-dot">.</div>
            </div>
          ) : loadingStripe ? (
            <div className="loading-container2">
              <div className="loading-text">Loading payment gateway</div>
              <div className="loading-dot">.</div>
              <div className="loading-dot">.</div>
              <div className="loading-dot">.</div>
            </div>
          ) : (
            pubKey && clientSecret && <CheckoutForm pubKey={pubKey} clientSecret={clientSecret} />
          )}
        </div>
      </div>

      <footer>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default InitiatePaymentPage;
