import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

const CheckoutForm = ({ pubKey, clientSecret }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (pubKey) {
      setStripePromise(loadStripe(pubKey));
    }
  }, [pubKey]);

  return (
    <div className="checkout-container">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

CheckoutForm.propTypes = {
  pubKey: PropTypes.string.isRequired,
  clientSecret: PropTypes.string.isRequired,
};

export default CheckoutForm;
