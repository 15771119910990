import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;

// Helper function to update clinicName based on device width
const updateClinicNameBasedOnWidth = (fullClinicName) => {
  const width = window.innerWidth;

  if (width <= 480) {
    return fullClinicName.length > 12 ? `${fullClinicName.substring(0, 12)} ...` : fullClinicName;
  } else if (width <= 768) {
    return fullClinicName.length > 18 ? `${fullClinicName.substring(0, 18)} ...` : fullClinicName;
  } else if (width <= 1024) {
    return fullClinicName.length > 25 ? `${fullClinicName.substring(0, 25)} ...` : fullClinicName;
  } else if (width <= 1366) {
    return fullClinicName.length > 35 ? `${fullClinicName.substring(0, 35)} ...` : fullClinicName;
  } else if (width <= 1920) {
    return fullClinicName.length > 45 ? `${fullClinicName.substring(0, 45)} ...` : fullClinicName;
  }
  return fullClinicName; // Default case if no condition matches
};

const PaymentReturnPage = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [config, setConfig] = useState(null); // API configuration
  const [clinicInfo, setClinicInfo] = useState({});
  const [patientInfo, setPatientInfo] = useState({});
  const [visitInfo, setVisitInfo] = useState({});
  const [fullClinicName, setFullClinicName] = useState('');

  useEffect(() => {
    // Load API configuration from config.json
    const fetchConfig = async () => {
      try {
        const response = await axios.get("/config.json");
        setConfig(response.data);
        console.log("Config loaded:", response.data);  // Log the config object
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);

  // Fetch payment status after the user returns to the page
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    const fetchStatus = async () => {
      if (!config) return; // Wait for config to be loaded

      try {
        // Use config API endpoint only after it's available
        const apiUrl = config?.API_PAYMENT_STATUS;
        if (!apiUrl) {
          console.error("API URL not available in config");
          return;
        }

        console.log(apiUrl);
        const response = await axios.post(apiUrl,
          { session_id: sessionId },
          { headers: { Accept: "application/json", "Content-Type": "application/json" } }
        );
        
        const responseData = response.data.RESPONSE;

        setStatus(responseData.paymentInfo.status);
        setCustomerEmail(responseData.paymentInfo.customer_email);
        
        // Set clinic and patient info
        setClinicInfo(responseData.clinicInfo);
        setPatientInfo(responseData.patient);
        setVisitInfo(responseData.visitInitInfo);
        setFullClinicName(responseData.clinicInfo.clinicName);
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };

    if (sessionId) {
      fetchStatus();
    }
  }, [config]);

  // Calculate clinic name based on the logic
  const clinicName = updateClinicNameBasedOnWidth(fullClinicName);

  // Conditional rendering based on payment status
  if (status === 'open') {
    return <Navigate to="/dashboard/initiate-payment" state={{ 
      clinicId: clinicInfo.clinicId,
      patientFirstName: patientInfo.patientFirstName,
      patientAvatar: patientInfo.patientAvatar,
      patientNotifications: patientInfo.patientNotifications,
      visitName: visitInfo.visitName,
      visitFee: visitInfo.visitFee,
      logoUrl: clinicInfo.logo,
      clinicName,
      fullClinicName: fullClinicName,
      visitId: visitInfo.visitTypeId,
      customerEmail 
    }} />;
  }

  if (status === 'complete') {
    return <Navigate to="/payment-success" state={{
      clinicId: clinicInfo.clinicId,
      patientFirstName: patientInfo.patientFirstName,
      patientAvatar: patientInfo.patientAvatar,
      patientNotifications: patientInfo.patientNotifications,
      visitName: visitInfo.visitName,
      visitFee: visitInfo.visitFee,
      logoUrl: clinicInfo.logo,
      clinicName,
      fullClinicName: fullClinicName,
      visitId: visitInfo.visitTypeId,
      customerEmail,
      patientPhone: patientInfo.phone.value,
      patientState: patientInfo.address.state,
    }} />;
  }

  if (status === 'failed') {
    return <Navigate to="/dashboard/initiate-payment" state={{
      clinicId: clinicInfo.clinicId,
      patientFirstName: patientInfo.patientFirstName,
      patientAvatar: patientInfo.patientAvatar,
      patientNotifications: patientInfo.patientNotifications,
      visitName: visitInfo.visitName,
      visitFee: visitInfo.visitFee,
      logoUrl: clinicInfo.logo,
      clinicName,
      fullClinicName: fullClinicName,
      visitId: visitInfo.visitTypeId,
      customerEmail,
      errorMessage: "Payment failed. Please add or select another card."
    }} />;
  }

  return (
    <div className="loading-container2">
        <div className="loading-text">Loading payment response</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
    </div>
  );
};

export default PaymentReturnPage;
