import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import UserGreeting from './UserGreeting';
import NavigationMenu from './NavigationMenu';
import 'survey-core/defaultV2.min.css';

const AppointmentScheduledPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // Retrieve state values
  const clinicId = state?.clinicId;
  const logoUrl = state?.logoUrl;
  const patientFirstName = state?.patientFirstName;
  const patientAvatar = state?.patientAvatar;
  const patientNotifications = state?.patientNotifications;
  const clinicName = state?.clinicName;
  const fullClinicName = state?.fullClinicName;
  // const selectedDoctor = state?.selectedDoctor;
  const selectedDoctorInfo = state?.selectedDoctorInfo;
  const selectedTimeslot = state?.selectedTimeslot;
  const selectedEndTimeslot = state?.selectedEndTimeslot;
  const selectedModality = state?.selectedModality;
  const dob = state?.dob;
  const visitName = state?.visitName;
  // const bookingQuestionnaireAltId = state?.bookingQuestionnaireAltId;
  // const patientPhone = state?.patientPhone;
  // const patientState = state?.patientState;

  console.log(selectedDoctorInfo);

  const handleSubmit = () => {
    navigate(`/${clinicId}`, { state });
  };

  return (
    <section className="container">
      {/* Header */}
      <Header 
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      {/* User Greeting */}
      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      {/* Survey Form */}
      <main className="main-content">
        <h5>Your appointment has been successfully scheduled!</h5>

        <div className="past-provider-selection scheduled-provider">
          <div className="div-provider-selection">
            <div className="div-2-provider-selection">
              <div className="div-3-provider-selection">
                <div className="div-4-provider-selection">
                  <img
                    loading="lazy"
                    src={selectedDoctorInfo.avatar}
                    className="img-provider-selection"
                    alt={`${selectedDoctorInfo.prefix} ${selectedDoctorInfo.firstName} ${selectedDoctorInfo.lastName} profile`}
                  />
                  <div>
                    {`${selectedDoctorInfo.prefix} ${selectedDoctorInfo.firstName} ${selectedDoctorInfo.lastName} ${selectedDoctorInfo.suffix}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="div-5-provider-selection">
              <div className="div-6-provider-selection">
                <div className="specialties-label-provider-selection">Specialties:</div>
                <div className="div-7-provider-selection">
                  {selectedDoctorInfo.specialties.map((specialty, i) => (
                    <div className="specialty-provider-selection" key={i}>
                      {specialty}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="div-5-provider-selection">
              <div className="div-6-provider-selection">
                <div className="specialties-label-provider-selection">Languages:</div>
                <div className="div-7-provider-selection">
                  {selectedDoctorInfo.languagesSpoken.map((language, i) => (
                    <div className="specialty-provider-selection" key={i}>
                      {language}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="appointment-card-appointment-scheduled-date" role="article">
          <div className="date-container-appointment-schedule-date">
            <div className="date-day-appointment-schedule-date">{new Date(dob).getDate()}</div>
            <div className="date-month-appointment-schedule-date">
              {new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date(dob))}
            </div>
          </div>
          <div className="appointment-details-appointment-schedule-date">
            <div className="appointment-title-appointment-schedule-date">{visitName}</div>
            <div className="appointment-time-appointment-schedule-date">
              <div className="time-slot-appointment-schedule-date">{selectedTimeslot} - {selectedEndTimeslot}</div>
            </div>
          </div>
        </div>

        
        {selectedModality === 'video' && (
          <div className={`radio-group-item-visit-type-${selectedModality === "video" ? "selection" : "unselected"}`}>
            <div className="radio-visit-type-selection">
              <div className="input-visit-type-selection">
                <input
                  type="radio"
                  name="visit-type"
                  id="video-visit"
                  className="radio-input-visit-type-selection"
                  aria-label="Select video visit"
                  checked={selectedModality === "video"}
                />
                <label htmlFor="video-visit" className="radio-label-visit-type-selection"></label>
              </div>
              <div className="div-3-visit-type-selection">
                <div className="text-visit-type-selection">Video Visit</div>
                <div className="badge-visit-type-selection">
                  <div className="badge-base-visit-type-selection">
                    <img
                      loading="lazy"
                      src="/assets/images/video.svg"
                      className="img-visit-type-selection"
                      alt="Video call icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedModality === 'audio' && (
          <div className={`radio-group-item-visit-type-${selectedModality === "audio" ? "selection" : "unselected"}`}>
            <div className="radio-visit-type-selection">
              <div className="input-visit-type-selection">
                <input
                  type="radio"
                  name="visit-type"
                  id="phone-visit"
                  className="radio-input-visit-type-selection"
                  aria-label="Select phone visit"
                  checked={selectedModality === "audio"}
                />
                <label htmlFor="phone-visit" className="radio-label-visit-type-selection"></label>
              </div>
              <div className="div-4-visit-type-selection">
                <div className="text-2-visit-type-selection">Phone Visit</div>
                <div className="badge-visit-type-selection">
                  <div className="badge-base-visit-type-selection">
                    <img
                      loading="lazy"
                      src="/assets/images/call.svg"
                      className="img-visit-type-selection"
                      alt="Phone call icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedModality === 'text' && (
          <div className={`radio-group-item-visit-type-${selectedModality === "text" ? "selection" : "unselected"}`}>
            <div className="radio-visit-type-selection">
              <div className="input-visit-type-selection">
                <input
                  type="radio"
                  name="visit-type"
                  id="chat-visit"
                  className="radio-input-visit-type-selection"
                  aria-label="Select chat visit"
                  checked={selectedModality === "text"}
                />
                <label htmlFor="chat-visit" className="radio-label-visit-type-selection"></label>
              </div>
              <div className="div-4-visit-type-selection">
                <div className="text-2-visit-type-selection">Chat Visit</div>
                <div className="badge-visit-type-selection">
                  <div className="badge-base-visit-type-selection">
                    <img
                      loading="lazy"
                      src="/assets/images/chat.svg"
                      className="img-visit-type-selection"
                      alt="Chat icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        
      </main>
      <footer>
        <nav
          className="navigation-container-collect-info"
          role="navigation"
          aria-label="Page navigation"
        >
          <button
            type="button"
            className="submit-button"
            aria-label="Finish"
            onClick={handleSubmit}
          >
            Finish
          </button>
        </nav>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default AppointmentScheduledPage;
