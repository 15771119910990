import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import UserGreeting from "./UserGreeting";
import NavigationMenu from "./NavigationMenu";

const WaitingQueuePage = () => {
  const { state } = useLocation();

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    logoUrl,
    clinicName,
    fullClinicName,
  } = state || {};



  return (
    <section className="container">
      <Header logoUrl={logoUrl} clinicName={clinicName} fullClinicName={fullClinicName} />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      <main className="main-content">
      <section className="temp-password-container">
        <p>Your provider will start the visit shortly.</p>
        <p>Make sure to turn your device's sound and notification on to be alerted when visit begins.</p>
        <img
                    loading="lazy"
                    src="assets/images/waiting.gif"
                    className="icon-image-waiting"
                    alt="Wait"
                />
        </section>
      </main>

      <footer>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default WaitingQueuePage;
